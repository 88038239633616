import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
  MuiEvent,
} from "@material-ui/data-grid";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      paddingLeft: theme.spacing(3),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 220,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    dataTable: {
      "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
        {
          outline: "none",
        },
    },
  })
);


interface PlayDataInterface {
  id: number;
  name: string;
  s_PV: string;
  s_LW: string;
  s_RW: string;
  s_long: string;
  s_middle: string;
  s_drive: string;
  s_rebound: string;
  s_freeThrow: string;
  s_fastBreak: string;
  s_sevenMeter_get: string;
  s_sevenMeter_shoot: string;
  s_setMiss: string;
  s_fastBreakMiss: string;
  s_total: string;
}

class PlayData implements PlayDataInterface {
  id: number;
  name: string;
  s_PV: string;
  s_LW: string;
  s_RW: string;
  s_long: string;
  s_middle: string;
  s_drive: string;
  s_rebound: string;
  s_freeThrow: string;
  s_fastBreak: string;
  s_sevenMeter_get: string;
  s_sevenMeter_shoot: string;
  s_setMiss: string;
  s_fastBreakMiss: string;
  s_total: string;

  PV_shoot: number;
  PV_goal: number;
  LW_shoot: number;
  LW_goal: number;
  RW_shoot: number;
  RW_goal: number;
  long_shoot: number;
  long_goal: number;
  middle_shoot: number;
  middle_goal: number;
  drive_shoot: number;
  drive_goal: number;
  rebound_shoot: number;
  rebound_goal: number;
  freeThrow_shoot: number;
  freeThrow_goal: number;
  fastBreak_shoot: number;
  fastBreak_goal: number;
  sevenMeter_get: number;
  sevenMeter_shoot: number;
  sevenMeter_goal: number;
  setMiss: number;
  fastBreakMiss: number;
  total_shoot: number;
  total_goal: number;

  // fetch()のための設定
  private fetchUrlPlayDataWithPlayer = (x: number) =>
    `${process.env.REACT_APP_API_ENDPOINT}v1/playdata/?player__id=${x}`;
  private fetchOptions: RequestInit = {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // スコア計算処理
  // pdはサーバーから取得したPlayData
  // pdから内部変数のスコアを場合に応じて加算する。
  private scoreCounter(pd: any): void {
    const result = pd.result;
    switch (pd.play_type) {
      case 1:
        this.total_shoot++;
        if (result === true) {
          this.PV_shoot++;
          this.PV_goal++;
          this.total_goal++;
        } else {
          this.PV_shoot++;
        }
        break;
      case 2:
        this.total_shoot++;
        if (result === true) {
          this.LW_shoot++;
          this.LW_goal++;
          this.total_goal++;
        } else {
          this.LW_shoot++;
        }
        break;
      case 3:
        this.total_shoot++;
        if (result === true) {
          this.RW_shoot++;
          this.RW_goal++;
          this.total_goal++;
        } else {
          this.RW_shoot++;
        }
        break;
      case 4:
        this.total_shoot++;
        if (result === true) {
          this.long_shoot++;
          this.long_goal++;
          this.total_goal++;
        } else {
          this.long_shoot++;
        }
        break;
      case 5:
        this.total_shoot++;
        if (result === true) {
          this.middle_shoot++;
          this.middle_goal++;
          this.total_goal++;
        } else {
          this.middle_shoot++;
        }
        break;
      case 6:
        this.total_shoot++;
        if (result === true) {
          this.drive_shoot++;
          this.drive_goal++;
          this.total_goal++;
        } else {
          this.drive_shoot++;
        }
        break;
      case 7:
        this.total_shoot++;
        if (result === true) {
          this.rebound_shoot++;
          this.rebound_goal++;
          this.total_goal++;
        } else {
          this.rebound_shoot++;
        }
        break;
      case 8:
        this.total_shoot++;
        if (result === true) {
          this.freeThrow_shoot++;
          this.freeThrow_goal++;
          this.total_goal++;
        } else {
          this.freeThrow_shoot++;
        }
        break;
      case 9:
        this.total_shoot++;
        if (result === true) {
          this.fastBreak_shoot++;
          this.fastBreak_goal++;
          this.total_goal++;
        } else {
          this.fastBreak_shoot++;
        }
        break;
      case 10:
        this.sevenMeter_get++;
        break;
      case 11:
        this.total_shoot++;
        if (result === true) {
          this.sevenMeter_shoot++;
          this.sevenMeter_goal++;
          this.total_goal++;
        } else {
          this.sevenMeter_shoot++;
        }
        break;
      case 12:
        this.setMiss++;
        break;
      case 13:
        this.fastBreakMiss++;
        break;
    }
  }

  constructor(id?: number) {
    if (id === undefined) id = 0;
    this.id = id;
    this.name = "";
    this.s_PV = "";
    this.s_LW = "";
    this.s_RW = "";
    this.s_long = "";
    this.s_middle = "";
    this.s_drive = "";
    this.s_rebound = "";
    this.s_freeThrow = "";
    this.s_fastBreak = "";
    this.s_sevenMeter_get = "";
    this.s_sevenMeter_shoot = "";
    this.s_setMiss = "";
    this.s_fastBreakMiss = "";
    this.s_total = "";

    this.PV_shoot = 0;
    this.PV_goal = 0;
    this.LW_shoot = 0;
    this.LW_goal = 0;
    this.RW_shoot = 0;
    this.RW_goal = 0;
    this.long_shoot = 0;
    this.long_goal = 0;
    this.middle_shoot  = 0;
    this.middle_goal = 0;
    this.drive_shoot = 0;
    this.drive_goal = 0;
    this.rebound_shoot = 0;
    this.rebound_goal = 0;
    this.freeThrow_shoot = 0;
    this.freeThrow_goal = 0;
    this.fastBreak_shoot = 0;
    this.fastBreak_goal = 0;
    this.sevenMeter_get = 0;
    this.sevenMeter_shoot = 0;
    this.sevenMeter_goal = 0;
    this.setMiss = 0;
    this.fastBreakMiss = 0;
    this.total_shoot = 0;
    this.total_goal = 0;
  }

  // 内部のスコア値から表示用の文字列を生成する
  setScoreStringWithInternalValue() {
    this.s_PV = `${this.PV_goal}/${this.PV_shoot}`;
    this.s_LW = `${this.LW_goal}/${this.LW_shoot}`;
    this.s_RW = `${this.RW_goal}/${this.RW_shoot}`;
    this.s_long = `${this.long_goal}/${this.long_shoot}`;
    this.s_middle = `${this.middle_goal}/${this.middle_shoot}`;
    this.s_drive = `${this.drive_goal}/${this.drive_shoot}`;
    this.s_rebound = `${this.rebound_goal}/${this.rebound_shoot}`;
    this.s_freeThrow = `${this.freeThrow_goal}/${this.freeThrow_shoot}`;
    this.s_fastBreak = `${this.fastBreak_goal}/${this.fastBreak_shoot}`;
    this.s_sevenMeter_get = `${this.sevenMeter_get}`;
    this.s_sevenMeter_shoot = `${this.sevenMeter_shoot}`;
    this.s_setMiss = `${this.setMiss}`;
    this.s_fastBreakMiss = `${this.fastBreakMiss}`;
    this.s_total = `${this.total_goal}/${this.total_shoot}`;
  }

  // サーバーから関連するすべてのPlayDataを取得し、それぞれのスコアを計算する。
  getDetailData() {
    return new Promise((resolve) => {
      fetch(this.fetchUrlPlayDataWithPlayer(this.id), this.fetchOptions)
            .then((res) => res.json())
            .then((res) => {
              res.forEach((pd: any) => {
                this.scoreCounter(pd);
              });
              resolve(0);
            });
    });
  }

  // 引数のplaydatasからそれぞれのスコアの合計を計算し、その合計を含む新しいplayDataの配列を返す
  static getTotalColScore(playdatas: Array<PlayData>) : Array<PlayData> {
    const total = new PlayData(0);
    total.name = "合計";
    playdatas.reduce((acc: PlayData, cur: PlayData) => {
      acc.PV_goal += cur.PV_goal;
      acc.PV_shoot += cur.PV_shoot;
      acc.LW_goal += cur.LW_goal;
      acc.LW_shoot += cur.LW_shoot;
      acc.RW_goal += cur.RW_goal;
      acc.RW_shoot += cur.RW_shoot;
      acc.long_shoot += cur.long_shoot;
      acc.long_goal += cur.long_goal;
      acc.middle_shoot += cur.middle_shoot;
      acc.middle_goal += cur.middle_goal;
      acc.drive_shoot += cur.drive_shoot;
      acc.drive_goal += cur.drive_goal;
      acc.rebound_shoot += cur.rebound_shoot;
      acc.rebound_goal += cur.rebound_goal;
      acc.freeThrow_shoot += cur.freeThrow_shoot;
      acc.freeThrow_goal += cur.freeThrow_goal;
      acc.fastBreak_shoot += cur.fastBreak_shoot;
      acc.fastBreak_goal += cur.fastBreak_goal;
      acc.sevenMeter_get += cur.sevenMeter_get;
      acc.sevenMeter_shoot += cur.sevenMeter_shoot;
      acc.sevenMeter_goal  += cur.sevenMeter_goal;
      acc.setMiss += cur.setMiss;
      acc.fastBreakMiss += cur.fastBreakMiss;
      acc.total_shoot += cur.total_shoot;
      acc.total_goal += cur.total_goal;
      return acc
    }, total);
    total.setScoreStringWithInternalValue();
    return [...playdatas, total];
  }
}

function Team() {
  const classes = useStyles();

  // チーム一覧を格納するstateでSlectで使用
  const [teamState, setTeamState] = useState({ data: [] });
  // Selectが選択しているチームID-1の値
  const [team_id, setTeamIdState] = useState(0);
  // dataGridのデータ
  const [rows, setRows] = useState<Array<PlayData>>([]);

  const history = useHistory();

  const fetchUrlTeam = `${process.env.REACT_APP_API_ENDPOINT}v1/team/`;
  const fetchUrlPlayerWithTeamId = (x: number) =>
    `${process.env.REACT_APP_API_ENDPOINT}v1/player/?team__id=${x}`;
  const fetchOptions: RequestInit = {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // チーム選択処理
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as number;

    fetch(fetchUrlPlayerWithTeamId(id), fetchOptions)
      .then((res) => res.json())
      .then((res) => {
        const promisedArray: Array<Promise<PlayData>> = res.map(
          (member: any) => {
            // mapの中でfetch()を使うとfetchが完了する前にmapが完了してしまい、正しい配列が生成されない
            // そこでPromiseを使って全てのfetchが完了するまでmapの返り値(promisedArray)の処理を待つ。
            return new Promise((resolve) => {
              const p = new PlayData(member.id);
              p.name = member.name;
              p.getDetailData().then((val) => {
                p.setScoreStringWithInternalValue();
                resolve(p);
              });
            });
          }
        );
        // promisedArrayの処理が完了するまで待つ。
        Promise.all(promisedArray).then((r: Array<PlayData>) => {
          // console.log(r);
          const newr = PlayData.getTotalColScore(r);
          // console.log(newr);
          setRows(newr);
        });
      });
  };

  // クリックで個人のページへ移動
  const rowClicked = (param: GridRowParams) => {
    history.push(`/member/${param.id}/`);
  };

  useEffect(() => {
    fetch(fetchUrlTeam, fetchOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        setTeamState({ data: result });
      });
  }, []);

  // DataGridの列の定義
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "名前",
      width: 200,
      hideSortIcons:  true,
    },
    {
      field: "s_PV",
      headerName: "ポスト",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_LW",
      headerName: "左サイド",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_RW",
      headerName: "右サイド",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_long",
      headerName: "ロング",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_middle",
      headerName: "ミドル",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_drive",
      headerName: "ドライブ",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_rebound",
      headerName: "リバウンド",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_freeThrow",
      headerName: "フリースロー",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_fastBreak",
      headerName: "速攻",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_sevenMeter_get",
      headerName: "7m獲得",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_sevenMeter_shoot",
      headerName: "7mシュート",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_setMiss",
      headerName: "セットミス",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_fastBreakMiss",
      headerName: "速攻ミス",
      width: 100,
      hideSortIcons: true,
    },
    {
      field: "s_total",
      headerName: "合計",
      width: 100,
      hideSortIcons: true,
    },
  ];

  return (
    <div>
      <div>
        <h1>チーム選択</h1>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">チーム</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={team_id}
            onChange={handleChange}
          >
            {teamState.data.map((row: { id: string; name: string }) => (
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <DataGrid
          className={classes.dataTable}
          rows={rows}
          columns={columns}
          onRowClick={rowClicked}
          disableSelectionOnClick
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnFilter
          disableDensitySelector
        />
      </div>
    </div>
  );
}

export default Team;
