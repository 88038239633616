import axios from "axios";
import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, makeStyles, Theme, createStyles  } from '@material-ui/core';
// import { HourglassEmpty } from "@material-ui/icons";
import { Legend, CartesianGrid, XAxis, YAxis, Tooltip,  ScatterChart, ZAxis, Scatter } from 'recharts';
// import MemberInfoPV from "./MemberInfo/MemberInfoPV";
import './MemberInfo.css';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableName: {
      'div & > *': {
        fontWeight: 'bolder'
      }
    },
    pieGraph: {

    },
  })
);


const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  method: "get",
  timeout: 5000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

interface Props {
  id: string;
}

interface State {
  id: string;
  name: string;
}

class MemberDetails extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      name: "",
    };
  }

  componentDidMount() {
    api({
      url: "v1/player/" + this.props.id + '/',
    }).then((response) => {
      // console.log(this.props);
      this.setState(response.data);
    });
  }

  render() {
    return (
      <div>
        <ul>
          <li>名前: {this.state.name}</li>
        </ul>
      </div>
    );
  }
}

interface PlayData {
  id: number;
  name: string;
  PV_shoot: number;
  PV_goal: number;
  LW_shoot: number;
  LW_goal: number
  RW_shoot: number;
  RW_goal: number;
  long_shoot: number;
  long_goal: number;
  middle_shoot: number;
  middle_goal: number;
  drive_shoot: number;
  drive_goal: number;
  rebound_shoot: number;
  rebound_goal: number;
  freeThrow_shoot: number;
  freeThrow_goal: number
  fastBreak_shoot: number;
  fastBreak_goal: number;
  sevenMeter_get: number;
  sevenMeter_shoot: number;
  sevenMeter_goal: number;
  setMiss: number;
  fastBreakMiss: number;
}

let sample_play_data: PlayData = {
  id: 1,
  name: "",
  PV_shoot: 0,
  PV_goal: 0,
  LW_shoot: 0,
  LW_goal: 0,
  RW_shoot: 0,
  RW_goal: 0,
  long_shoot: 0,
  long_goal: 0,
  middle_shoot: 0,
  middle_goal: 0,
  drive_shoot: 0,
  drive_goal: 0,
  rebound_shoot: 0,
  rebound_goal: 0,
  freeThrow_shoot: 0,
  freeThrow_goal: 0,
  fastBreak_shoot: 0,
  fastBreak_goal: 0,
  sevenMeter_get: 0,
  sevenMeter_shoot: 0,
  sevenMeter_goal: 0,
  setMiss: 0,
  fastBreakMiss: 0,
}

interface shootCourse{
  x_coordinate: number;
  y_coordinate: number;
  z_coordinate: number;
}

// let shoot_course_nogoal:number[][] = new Array();






function addPlayerResult(player: number, play_type: number, result: boolean, pd: PlayData) {
  // console.log(pd)    
  switch (play_type) {
        case 1:
          if (result === true) {
            pd.PV_shoot++;
            pd.PV_goal++;
          } else {
            pd.PV_shoot++;
          }
          break;
        case 2:
          if (result === true) {
            pd.LW_shoot++;
            pd.LW_goal++;
          } else {
            pd.LW_shoot++;
          }
          break;
        case 3:
          if (result === true) {
            pd.RW_shoot++;
            pd.RW_goal++;
          } else {
            pd.RW_shoot++;
          }
          break;
        case 4:
          if (result === true) {
            pd.long_shoot++;
            pd.long_goal++;
          } else {
            pd.long_shoot++;
          }
          break;
        case 5:
          if (result === true) {
            pd.middle_shoot++;
            pd.middle_goal++;
          } else {
            pd.middle_shoot++;
          }
          break;
        case 6:
          if (result === true) {
            pd.drive_shoot++;
            pd.drive_goal++;
          } else {
            pd.drive_shoot++;
          }
          break;
        case 7:
          if (result === true) {
            pd.rebound_shoot++;
            pd.rebound_goal++;
          } else {
            pd.rebound_shoot++;
          }
          break;
        case 8:
          if (result === true) {
            pd.freeThrow_shoot++;
            pd.freeThrow_goal++;
          } else {
            pd.freeThrow_shoot++;
          }
          break;
        case 9:
          if (result === true) {
            pd.fastBreak_shoot++;
            pd.fastBreak_goal++;
          } else {
            pd.fastBreak_shoot++;
          }
          break;
        case 10:
          pd.sevenMeter_get++;
          break;
        case 11:
          if (result === true) {
            pd.sevenMeter_shoot++;
            pd.sevenMeter_goal++;
          } else {
            pd.sevenMeter_shoot++;
          }
          break;
        case 12:
          pd.setMiss++;
          break;
        case 13:
          pd.fastBreakMiss++;
          break;
      }
}


function ScoreSheet(props: any) {

  const classes = useStyles();
  // const [member, setMemberState] = useState({ data: [] })

  const [playDataState, setPlayDataState] = useState<PlayData>(sample_play_data)

  useEffect(() => {
    let playdata: PlayData = sample_play_data;
    
    api({
      url: "v1/player/" + props.id + "/",
    }).then((response) => {
      // console.log(props)
      // setMemberState({ data: response.data });
      playdata.id = response.data.id;
      playdata.name = response.data.name
      // console.log(plaidydata)
    
 
      api({
        url: "v1/playdata/?player__id=" + props.id,
      }).then((response) => {
        for (var playDataItem of response.data) {
            // console.log(playDataItem);
            addPlayerResult(playDataItem.player, playDataItem.play_type, playDataItem.result, playdata)
            setPlayDataState(playdata)
            // console.log(playDataState.drive_goal)
        }

        // let playDataItem = response.data;
        // console.log(playDataItem);
        // addPlayerResult(playDataItem.player, playDataItem.play_type, playDataItem.result, playdata)
        // setPlayDataState(playdata.concat())
        // console.log(playdata);
        });
      // console.log(playDataItem)
    })
  }, [props])

  return (
    <div>
      <h2>スコアシート</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableName}>
              <TableCell>名前</TableCell>
              <TableCell align="right">ポスト</TableCell>
              <TableCell align="right">左サイド</TableCell>
              <TableCell align="right">右サイド</TableCell>
              <TableCell align="right">ロング</TableCell>
              <TableCell align="right">ミドル</TableCell>
              <TableCell align="right">ドライブ</TableCell>
              <TableCell align="right">リバウンド</TableCell>
              <TableCell align="right">フリースロー</TableCell>
              <TableCell align="right">速攻</TableCell>
              <TableCell align="right">7m獲得</TableCell>
              <TableCell align="right">7mシュート</TableCell>
              <TableCell align="right">セットミス</TableCell>
              <TableCell align="right">速攻ミス</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow key={playDataState.id} >
                <TableCell component="th" scope="row">
                  {playDataState.name}
                </TableCell>
                <TableCell align="right">{playDataState.PV_goal}/{playDataState.PV_shoot}</TableCell>
                <TableCell align="right">{playDataState.LW_goal}/{playDataState.LW_shoot}</TableCell>
                <TableCell align="right">{playDataState.RW_goal}/{playDataState.RW_shoot}</TableCell>
                <TableCell align="right">{playDataState.long_goal}/{playDataState.long_shoot}</TableCell>
                <TableCell align="right">{playDataState.middle_goal}/{playDataState.middle_shoot}</TableCell>
                <TableCell align="right">{playDataState.drive_goal}/{playDataState.drive_shoot}</TableCell>
                <TableCell align="right">{playDataState.rebound_goal}/{playDataState.rebound_shoot}</TableCell>
                <TableCell align="right">{playDataState.freeThrow_goal}/{playDataState.freeThrow_shoot}</TableCell>
                <TableCell align="right">{playDataState.fastBreak_goal}/{playDataState.fastBreak_shoot}</TableCell>
                <TableCell align="right">{playDataState.sevenMeter_get}</TableCell>
                <TableCell align="right">{playDataState.sevenMeter_goal}/{playDataState.sevenMeter_shoot}</TableCell>
                <TableCell align="right">{playDataState.setMiss}</TableCell>
                <TableCell align="right">{playDataState.fastBreakMiss}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function ShootCourse(props: any) {
  // const classes = useStyles();

  let base_shoot_course_nogoal= [
    { x: 1, y: 7, z: 0 } ,
    { x: 3, y: 7, z: 0 },
    { x: 5, y: 7, z: 0 },
    { x: 7, y: 7, z: 0 },
    { x: 9, y: 7, z: 0 },
    { x: 1, y: 5, z: 0 },
    { x: 2.5, y: 5, z: 0 },
    { x: 4.5, y: 5, z: 0 },
    { x: 6.5, y: 5, z: 0 },
    { x: 9, y: 5, z: 0 },
    { x: 1, y: 3, z: 0 },
    { x: 2.5, y: 3, z: 0 },
    { x: 4.5, y: 3, z: 0 },
    { x: 6.5, y: 3, z: 0 },
    { x: 9, y: 3, z: 0 },
    { x: 1, y: 1, z: 0 },
    { x: 2.5, y: 1, z: 0 },
    { x: 4.5, y: 1, z: 0 },
    { x: 6.5, y: 1, z: 0 },
    { x: 9, y: 1, z: 0 },
  ];

  let base_shoot_course_goal= [
    { x: 3.5, y: 5, z: 0 },
    { x: 5.5, y: 5, z: 0 },
    { x: 7.5, y: 5, z: 0 },
    { x: 3.5, y: 3, z: 0 },
    { x: 5.5, y: 3, z: 0 },
    { x: 7.5, y: 3, z: 0 },
    { x: 3.5, y: 1, z: 0 },
    { x: 5.5, y: 1, z: 0 },
    { x: 7.5, y: 1, z: 0 },
  ];

  let goalLineCoordinate = [
    { x: 2, y: 0, z: 0 },
    { x: 2, y: 6, z: 0 },
    { x: 8, y: 6, z: 0 },
    { x: 8, y: 0, z: 0 },
  ];
  const [scatterDataState_nogoal, setScatterDataState_nogoal] = useState<Array<any>>()
  const [scatterDataState_goal, setScatterDataState_goal] = useState<Array<any>>()


  function countShootCourse_goal(shoot_data:Array<any>) {
    let shoot_course_goal_z = {
      goal_data01:0,
      goal_data02:0,
      goal_data03:0,
      goal_data04:0,
      goal_data05:0,
      goal_data06:0,
      goal_data07:0,
      goal_data08:0,
      goal_data09:0,
    }

    shoot_data.map((item)=> {
      if(item.result === true){
        switch (item.shoot_type){
          case 8:
            shoot_course_goal_z.goal_data01+=10;
            break;
          case 9:
            shoot_course_goal_z.goal_data02+=10;
            break;
          case 10:
            shoot_course_goal_z.goal_data03+=10;
            break;
          case 13:
            shoot_course_goal_z.goal_data04+=10;
            break;
          case 14:
            shoot_course_goal_z.goal_data05+=10;
            break;
          case 15:
            shoot_course_goal_z.goal_data06+=10;
            break;
          case 18:
              shoot_course_goal_z.goal_data07+=10;
            break;
          case 19:
              shoot_course_goal_z.goal_data08+=10;
            break;
          case 20:
            shoot_course_goal_z.goal_data09+=10;
            break;

        }
      }
    })
      return shoot_course_goal_z;
  }

  useEffect(() => {
    // let shoot_course_data1: shootCourse = shoot_course_data1;
    api({
      url: "v1/playdata/?player__id=" + props.id,
    }).then((response) => {
      console.log(response)
      let result = countShootCourse_goal(response.data);
      console.log(result)
      base_shoot_course_goal[0].z =result.goal_data01;
      base_shoot_course_goal[1].z =result.goal_data02;
      base_shoot_course_goal[2].z =result.goal_data03;
      base_shoot_course_goal[3].z =result.goal_data04;
      base_shoot_course_goal[4].z =result.goal_data05;
      base_shoot_course_goal[5].z =result.goal_data06;
      base_shoot_course_goal[6].z =result.goal_data07;
      base_shoot_course_goal[7].z =result.goal_data08;
      base_shoot_course_goal[8].z =result.goal_data09;

      setScatterDataState_goal(base_shoot_course_goal)
      console.log(scatterDataState_goal)


      }
    );
  },[])
    
 
  function countShootCourse_nogoal(shoot_data:Array<any>) {
    let shoot_course_nogoal_z = {
      nogoal_data01:0,
      nogoal_data02:0,
      nogoal_data03:0,
      nogoal_data04:0,
      nogoal_data05:0,
      nogoal_data06:0,
      nogoal_data07:0,
      nogoal_data08:0,
      nogoal_data09:0,
      nogoal_data10:0,
      nogoal_data11:0,
      nogoal_data12:0,
      nogoal_data13:0,
      nogoal_data14:0,
      nogoal_data15:0,
      nogoal_data16:0,
      nogoal_data17:0,
      nogoal_data18:0,
      nogoal_data19:0,
      nogoal_data20:0,
    }

    shoot_data.map((item) => {
      switch (item.shoot_type){
        case 2:
          shoot_course_nogoal_z.nogoal_data01+=10;
          break;
        case 3:
          shoot_course_nogoal_z.nogoal_data02+=10;
          break;
        case 4:
          shoot_course_nogoal_z.nogoal_data03+=10;
          break;
        case 5:
          shoot_course_nogoal_z.nogoal_data04+=10;
          break;
        case 6:
          shoot_course_nogoal_z.nogoal_data05+=10;
          break;
        case 7:
          shoot_course_nogoal_z.nogoal_data06+=10;
          break;
        case 8:
          shoot_course_nogoal_z.nogoal_data07+=10;
          break;
        case 9:
          shoot_course_nogoal_z.nogoal_data08+=10;   
          break;
        case 10:
          shoot_course_nogoal_z.nogoal_data09+=10;
          break;
        case 11:
          shoot_course_nogoal_z.nogoal_data10+=10;
          break;
        case 12:
          shoot_course_nogoal_z.nogoal_data11+=10;
          break;
        case 13:
          shoot_course_nogoal_z.nogoal_data12+=10;
          break;
        case 14:
          shoot_course_nogoal_z.nogoal_data13+=10;
          break;
        case 15:
          shoot_course_nogoal_z.nogoal_data14+=10;
          break;
        case 16:
          shoot_course_nogoal_z.nogoal_data15+=10;
          break;
        case 17:
          shoot_course_nogoal_z.nogoal_data16+=10;
          break;
        case 18:
          shoot_course_nogoal_z.nogoal_data17+=10;
          break;
        case 19:
          shoot_course_nogoal_z.nogoal_data18+=10;
          break;
        case 20:
          shoot_course_nogoal_z.nogoal_data19+=10;
          break;
        case 21:
          shoot_course_nogoal_z.nogoal_data20+=10;
          break;
      }
    })

    return shoot_course_nogoal_z;
  }
  useEffect(() => {
    // let shoot_course_data1: shootCourse = shoot_course_data1;
    api({
      url: "v1/playdata/?player__id=" + props.id +"&result=false",
    }).then((response) => {
      console.log(response)
      let result = countShootCourse_nogoal(response.data);
      // console.log(result)
      base_shoot_course_nogoal[0].z =result.nogoal_data01;
      base_shoot_course_nogoal[1].z =result.nogoal_data02;
      base_shoot_course_nogoal[2].z =result.nogoal_data03;
      base_shoot_course_nogoal[3].z =result.nogoal_data04;
      base_shoot_course_nogoal[4].z =result.nogoal_data05;
      base_shoot_course_nogoal[5].z =result.nogoal_data06;
      base_shoot_course_nogoal[6].z =result.nogoal_data07;
      base_shoot_course_nogoal[7].z =result.nogoal_data08;
      base_shoot_course_nogoal[8].z =result.nogoal_data09;
      base_shoot_course_nogoal[9].z =result.nogoal_data10;
      base_shoot_course_nogoal[10].z =result.nogoal_data11;
      base_shoot_course_nogoal[11].z =result.nogoal_data12;
      base_shoot_course_nogoal[12].z =result.nogoal_data13;
      base_shoot_course_nogoal[13].z =result.nogoal_data14;
      base_shoot_course_nogoal[14].z =result.nogoal_data15;
      base_shoot_course_nogoal[15].z =result.nogoal_data16;
      base_shoot_course_nogoal[16].z =result.nogoal_data17;
      base_shoot_course_nogoal[17].z =result.nogoal_data18;
      base_shoot_course_nogoal[18].z =result.nogoal_data19;
      base_shoot_course_nogoal[19].z =result.nogoal_data20;
      console.log(base_shoot_course_nogoal)
      setScatterDataState_nogoal(base_shoot_course_nogoal)
      console.log(scatterDataState_nogoal)


      }
    );
  },[])



  // console.log(sample)

  return(
    <div>
      {/* {console.log(scatterDataState_nogoal)} */}
      <ScatterChart width={500} height={300}
        margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
        <CartesianGrid strokeDasharray="8 8 1"  />
        <XAxis dataKey="x" type="number" domain={[0, 10]} tickCount={10} interval={0} />
        <YAxis dataKey="y" domain={[0, 8]} />
        <ZAxis dataKey="z" range={[10,1000]}/>
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        {/* <Scatter name="No Goal" data={sample} fill="#8884d8" shape="cross" /> */}
        <Scatter name="No Goal" data={scatterDataState_nogoal} fill="#8884d8" shape="cross" />
        <Scatter name="Goal" data={scatterDataState_goal} fill="#82ca9d" shape="diamond" />
        {/* <Scatter name="Goal" data={data02} fill="#82ca9d" /> */}
        <Scatter name="GoalLine" data={goalLineCoordinate} fill="#ff0000" line shape="cross" />
      </ScatterChart>
    </div>
  )
}

// function Count_shootcourse (shoot_type:number, result: boolean, s1:shootCourse_goal, s2:shootCourse_nogoal){
//   switch (shoot_type) {
//       case1:



//   }
// } 

function MemberInfo(props: any) {
  const classes = useStyles();
  const id = props.match.params.memberid
  return (
    <div>
      <h1>個人情報{id}</h1>
      <MemberDetails id={id} />
      <ScoreSheet id={id}/>
      <ShootCourse id={id}/>
      {/* <MemberInfoPV id={id} /> */}
      {/* <h2>
        まもなくです。。。
      </h2> */}
      <div>
        <div>
          <h3 className="test1">1/6</h3>
          <h3 className="test2">4/5</h3>
          <h3 className="test3">1/1</h3>
          <h3 className="test4">3/2</h3>
          <h3 className="test5">0/5</h3>
          <h3 className="test6">1/1</h3>
        </div>
        <img src="/HandBallCourt_2.png" className="picture"/>
      </div>
    </div>
  );
}

export default MemberInfo;
// function useStyles() {
//   throw new Error("Function not implemented.");
// }