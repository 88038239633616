import React from 'react';
import logo from "./logo.svg";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { create } from 'domain';
import { Typography } from '@material-ui/core';

function Home() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <header className="App-header">
            <h1>
                HandBall Analyzer
            </h1>
            <img src={logo} className="App-logo" alt="logo" />

            <p className="sub-title">
                Welcome to  Handballer 
            </p>
            <Link to="/inputgame" style={{ textDecoration: "none" }}>
                <Button variant="contained" color="primary">
                    Let's Play game!!
                </Button>
            </Link>
            <br />
            <br />
            <Button variant="contained" color="primary" onClick={handleClickOpen}>アプリについて(version情報)</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"この試合分析アプリ(varsion1.0)について"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                        近代スポーツにおいて統計的な試合分析はとても重要である。
                        実際に、バレーボールやサッカーではタブレットを用い、選手への指示や試合分析が行われている。
                        また監督、コーチがイヤホンをしている姿を目にしたことがあるだろう。                        
                        </Typography>
                        <Typography gutterBottom>
                        2020年ハンドボール競技規則の変更により交代地域(ベンチ)に技術的機器の持ち込みが可能となった。
                        これに先立ち、タブレット上で試合分析が可能かつ選手にフィードバックが可能なようにWEBアプリケーションの作成に取り組んだ。
                        </Typography>
                        <Typography gutterBottom>
                            尚、現在も開発途中のため至らない点が多いですが、暖かい目で見守っていただけると助かります。
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogTitle id="alert-dialog-title">{"Ver2.0に更新"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            一年を経てVer2.0へ更新                    
                        </Typography>
                        <Typography gutterBottom>
                            ・シュートコースのデータが入力、表示が可能に！
                        </Typography>
                        <Typography gutterBottom>
                            ・個人メンバーのデータが表示できるように！
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogTitle id="alert-dialog-title">{"Ver2.1に更新"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            ・グラフの凡例が日本語に対応しました。

                        </Typography>
                        <Typography gutterBottom>
                        ・相手選手のデータ入力に対応しました。
                                試合入力画面にて相手チームの選択が可能です。
                        </Typography>
                        <Typography gutterBottom>
                        ・試合結果一覧の日付が見やすくなりました。
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/* <Button variant="text" color="inherit" onClick={handleClickOpen}>Ver2.0</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Ver2.0に更新"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            一年を経てVer2.0へ更新                    
                        </Typography>
                        <Typography gutterBottom>
                            ・シュートコースのデータが入力、表示が可能に！
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog> */}
        </header>
    );
}

export default Home;
