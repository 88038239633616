import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import { makeStyles, Theme } from '@material-ui/core/styles';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  method: 'get',
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
})

interface PlayData {
  id: number;
  name: string;
  PV_shoot: number;
  PV_goal: number;
  LW_shoot: number;
  LW_goal: number
  RW_shoot: number;
  RW_goal: number;
  long_shoot: number;
  long_goal: number;
  middle_shoot: number;
  middle_goal: number;
  drive_shoot: number;
  drive_goal: number;
  rebound_shoot: number;
  rebound_goal: number;
  freeThrow_shoot: number;
  freeThrow_goal: number
  fastBreak_shoot: number;
  fastBreak_goal: number;
  sevenMeter_get: number;
  sevenMeter_shoot: number;
  sevenMeter_goal: number;
  setMiss: number;
  fastBreakMiss: number;
  total_shoot: number;
  total_goal: number;
}

let sample_play_data: PlayData = {
  id: 1,
  name: "",
  PV_shoot: 0,
  PV_goal: 0,
  LW_shoot: 0,
  LW_goal: 0,
  RW_shoot: 0,
  RW_goal: 0,
  long_shoot: 0,
  long_goal: 0,
  middle_shoot: 0,
  middle_goal: 0,
  drive_shoot: 0,
  drive_goal: 0,
  rebound_shoot: 0,
  rebound_goal: 0,
  freeThrow_shoot: 0,
  freeThrow_goal: 0,
  fastBreak_shoot: 0,
  fastBreak_goal: 0,
  sevenMeter_get: 0,
  sevenMeter_shoot: 0,
  sevenMeter_goal: 0,
  setMiss: 0,
  fastBreakMiss: 0,
  total_shoot: 0,
  total_goal: 0
}

//ポジションごとのシュート数を合計する
function addPlayerResult(player: number, play_type: number, result: boolean, pd: Array<PlayData>) {
  pd.forEach(element => {
    if (element.id === player) {
      switch (play_type) {
        case 1:
          element.total_shoot++;
          if (result === true) {
            element.PV_shoot++;
            element.PV_goal++;
            element.total_goal++;
          } else {
            element.PV_shoot++;
          }
          break;
        case 2:
          element.total_shoot++;
          if (result === true) {
            element.LW_shoot++;
            element.LW_goal++;
            element.total_goal++;
          } else {
            element.LW_shoot++;
          }
          break;
        case 3:
          element.total_shoot++;
          if (result === true) {
            element.RW_shoot++;
            element.RW_goal++;
            element.total_goal++;
          } else {
            element.RW_shoot++;
          }
          break;
        case 4:
          element.total_shoot++;
          if (result === true) {
            element.long_shoot++;
            element.long_goal++;
            element.total_goal++;
          } else {
            element.long_shoot++;
          }
          break;
        case 5:
          element.total_shoot++;
          if (result === true) {
            element.middle_shoot++;
            element.middle_goal++;
            element.total_goal++;
          } else {
            element.middle_shoot++;
          }
          break;
        case 6:
          element.total_shoot++;
          if (result === true) {
            element.drive_shoot++;
            element.drive_goal++;
            element.total_goal++;
          } else {
            element.drive_shoot++;
          }
          break;
        case 7:
          element.total_shoot++;
          if (result === true) {
            element.rebound_shoot++;
            element.rebound_goal++;
            element.total_goal++;
          } else {
            element.rebound_shoot++;
          }
          break;
        case 8:
          element.total_shoot++;
          if (result === true) {
            element.freeThrow_shoot++;
            element.freeThrow_goal++;
            element.total_goal++;
          } else {
            element.freeThrow_shoot++;
          }
          break;
        case 9:
          element.total_shoot++;
          if (result === true) {
            element.fastBreak_shoot++;
            element.fastBreak_goal++;
            element.total_goal++;
          } else {
            element.fastBreak_shoot++;
          }
          break;
        case 10:
          element.sevenMeter_get++;
          break;
        case 11:
          element.total_shoot++;
          if (result === true) {
            element.sevenMeter_shoot++;
            element.sevenMeter_goal++;
            element.total_goal++;
          } else {
            element.sevenMeter_shoot++;
          }
          break;
        case 12:
          element.setMiss++;
          break;
        case 13:
          element.fastBreakMiss++;
          break;
      }
    }
  });
}

function PlayerList(proos: any) {
  // const [member, setMemberState] = useState({ data: [] })
  const [playDataState, setPlayDataState] = useState<Array<PlayData>>([])

  useEffect(() => {
    let playdata: Array<PlayData> = [];
    //名前の取得
    api({
      url: "v1/player/",
    }).then((response) => {
      // setMemberState({ data: response.data });
      response.data.map((m: any) => {
        const pos = playdata.push({ ...sample_play_data }) - 1;
        playdata[pos].id = m.id;
        playdata[pos].name = m.name
        return 0;
      })
      //PlayDataの取得
      api({
        url: "v1/playdata/",
      }).then((response) => {
        for (var playDataItem of response.data) {

          addPlayerResult(playDataItem.player, playDataItem.play_type, playDataItem.result, playdata)
          setPlayDataState(playdata.concat())
        }
      });
    });
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead> 
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell align="right">ポスト</TableCell>
            <TableCell align="right">左サイド</TableCell>
            <TableCell align="right">右サイド</TableCell>
            <TableCell align="right">ロング</TableCell>
            <TableCell align="right">ミドル</TableCell>
            <TableCell align="right">ドライブ</TableCell>
            <TableCell align="right">リバウンド</TableCell>
            <TableCell align="right">フリースロー</TableCell>
            <TableCell align="right">速攻</TableCell>
            <TableCell align="right">7m獲得</TableCell>
            <TableCell align="right">7mシュート</TableCell>
            <TableCell align="right">セットミス</TableCell>
            <TableCell align="right">速攻ミス</TableCell>
            <TableCell align="right">合計</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {playDataState.map((row: PlayData) => (
            <TableRow key={row.id} component={Link} to={`/member/${row.id}/`}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.PV_goal}/{row.PV_shoot}</TableCell>
              <TableCell align="right">{row.LW_goal}/{row.LW_shoot}</TableCell>
              <TableCell align="right">{row.RW_goal}/{row.RW_shoot}</TableCell>
              <TableCell align="right">{row.long_goal}/{row.long_shoot}</TableCell>
              <TableCell align="right">{row.middle_goal}/{row.middle_shoot}</TableCell>
              <TableCell align="right">{row.drive_goal}/{row.drive_shoot}</TableCell>
              <TableCell align="right">{row.rebound_goal}/{row.rebound_shoot}</TableCell>
              <TableCell align="right">{row.freeThrow_goal}/{row.freeThrow_shoot}</TableCell>
              <TableCell align="right">{row.fastBreak_goal}/{row.fastBreak_shoot}</TableCell>
              <TableCell align="right">{row.sevenMeter_get}</TableCell>
              <TableCell align="right">{row.sevenMeter_goal}/{row.sevenMeter_shoot}</TableCell>
              <TableCell align="right">{row.setMiss}</TableCell>
              <TableCell align="right">{row.fastBreakMiss}</TableCell>
              <TableCell align="right">{row.total_goal}/ {row.total_shoot}</TableCell>

            </TableRow>
          ))}
          <TableRow>
          <TableCell>   </TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>
          <TableCell>合計</TableCell>


          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function MemberList(props: any) {

  return (
    <div className="App-spacing">
      <h1>メンバーリスト</h1>
      <PlayerList />
    </div>
  );
}
export default MemberList;
