import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
} from "@material-ui/data-grid";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataTable: {
      "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
        {
          outline: "none",
        },
    },
  })
);

const fetchUrlGames = `${process.env.REACT_APP_API_ENDPOINT}v1/games/`;
const fetchOptions: RequestInit = {
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

function GameList() {
  const classes = useStyles();

  const history = useHistory();

  const [gList, setGL] = useState<Array<any>>([]);

  useEffect(() => {
    fetch(fetchUrlGames, fetchOptions)
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        // 日付をDate型に変更する
        const dateFormattedGames = result.map((game: any) => {
          game.datetime = new Date(game.datetime);
          return game;
        });
        setGL(dateFormattedGames);
      });
  }, []);

  // クリックで個人のページへ移動
  const rowClicked = (param: GridRowParams) => {
    history.push(`/game/${param.id}/`);
  };

  // DataGridの列の定義
  const columns: GridColDef[] = [
    {
      field: "datetime",
      headerName: "日付",
      width: 200,
      type: "dateTime",
      valueFormatter: (params: GridValueFormatterParams) => {
        // 日本流の日付表示に変更
        const date = new Date(params.value as string);
        const result = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}${" "}${date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`;
        return result;
      },
    },
    {
      field: "game_name",
      headerName: "対戦相手",
      width: 200,
      hideSortIcons: true,
      type: "string",
    },
    {
      field: "points_1",
      headerName: "自チーム得点",
      width: 160,
      align: "right",
      hideSortIcons: true,
      type: "number",
    },
    {
      field: "",
      headerName: " ",
      width: 50,
      hideSortIcons: true,
      type: "string",
    },
    {
      field: "points_2",
      headerName: "相手チーム得点",
      width: 160,
      align: "left",
      hideSortIcons: true,
      type: "number",
    },
    {
      field: "movie_url",
      headerName: "YoutubeID",
      width: 100,
      hideSortIcons: true,
      type: "string",
    },
  ];

  return (
    <div>
      <div>
        <h1>試合結果一覧</h1>
      </div>
      <div>
        <DataGrid
          className={classes.dataTable}
          rows={gList}
          columns={columns}
          onRowClick={rowClicked}
          disableSelectionOnClick
          autoHeight
          hideFooter
          disableColumnMenu
          disableColumnFilter
          disableDensitySelector
        />
      </div>
    </div>
  );
}

export default GameList;
