import React from 'react';
import clsx from 'clsx';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Button from '@material-ui/core/Button';
import Home from './Home'
import Myelem from './Myelem'
import Movie from './Movie'
import PlayGround from "./PlayGround";
import GameList from "./GameList";
import MemberList from "./MemberList";
// import MemberAdd from "./MemberAdd";
import InputGame from "./InputGame";
import PlayData from "./PlayData";
import GameInfo from "./GameInfo";
import MemberInfo from "./MemberInfo";
import Team from "./Team";
import { Link, BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import ResponsiveDrawer from "./components/ResponsiveDrawer";
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Divider, Drawer, List } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ListIcon from '@material-ui/icons/List';
import MovieIcon from '@material-ui/icons/Movie';
import HomeIcon from '@material-ui/icons/Home';
import ColorsCommon from "@material-ui/core/colors/common";



const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar1: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    logoutButton: {
      display:'absolute',
      marginLeft: "75%"

    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(8),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
  }),
);

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Router>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open)}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Link to="/">
              <IconButton color="inherit">
                <HomeIcon style={{ color: ColorsCommon.white }} />
              </IconButton>
            </Link>
            {/* <Button color="inherit">Logout</Button> */}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <List>
            <Link to="/inputgame" style={{ textDecoration: "none" }}>
              <ListItem button key="試合入力">
                <ListItemIcon>
                  <SportsHandballIcon />
                </ListItemIcon>
                <ListItemText primary="試合入力" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <Link to="/team" style={{ textDecoration: "none" }}>
              <ListItem button key="チーム">
                <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon>
                <ListItemText primary="チーム" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/game" style={{ textDecoration: "none" }}>
              <ListItem button key="試合結果一覧">
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="試合結果一覧" />
              </ListItem>
            </Link>
          </List>
          <List>
            <Link to="/movie" style={{ textDecoration: "none" }}>
              <ListItem button key="試合動画">
                <ListItemIcon>
                  <MovieIcon />
                </ListItemIcon>
                <ListItemText primary="試合動画" />
              </ListItem>
            </Link>
          </List>
          <Divider />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/myelem" component={Myelem} />
            <Route path="/movie" component={Movie} />
            <Route path="/playground" component={PlayGround} />
            <Route exact path="/member" component={MemberList} />
            <Route exact path="/member/:memberid/" component={MemberInfo} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/inputgame" component={InputGame} />
            <Route exact path="/inputgame/:gameid/" component={PlayData} />
            <Route exact path="/game" component={GameList} />
            <Route exact path="/game/:gameid/" component={GameInfo} />
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
