import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import './PlayDate.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';



import { useHistory } from "react-router-dom";


//import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({

// });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      'div & > *': {
        margin: theme.spacing(1),
        marginTop: 20,
      },
    },
    playButton: {
      '& > *':{
        margin: theme.spacing(1),
      }
    },
    goalButton: {
      '& > *': {
        margin: theme.spacing(2)
      }
    },
    endButton: {
      margin: theme.spacing(1),
      position:"static",
    },
    positionButton: {
      marginInlineStart:100
      
    },
  }),
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  method: "get",
  timeout: 5000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

// interface HogeState { color :  'default' | 'inherit' | 'primary' | 'secondary' }
// interface HogeProps { color :  'default' | 'inherit' | 'primary' | 'secondary' }

// class Hoge extends React.Component<HogeProps, HogeState> {
//   clickHandler: any;
//   constructor(props:HogeProps) {
//     super(props);
//     this.state = { color: "primary" };
//     //this.clickHandler = this.clickHandler.bind(this);
//   }
//   render() {
//     let newValue: string = "sencondary";
//     const clickHandler = (e: any) => {
//       const newValue = "secondary";
//       this.setState({ color: newValue });
//     };

//     return (
//       <div>
//         <Button
//           variant="contained"
//           color={this.state.color}
//           size="small"
//           onClick={clickHandler}
//         >
//           PV
//         </Button>
//       </div>
//     );
//   }
// }



function PlayData(props: any) {
  const classes = useStyles();

  interface Playdata {
    player: Number,
    result: boolean,
    play_type: Number,
    shoot_type: Number,
    game: Number
  }
  
  let pd : Playdata = {
    player : 1,
    result: false,
    play_type: 2,
    shoot_type: 1,
    game: props.match.params.gameid
  }

  const [memberState, setMemberState] = useState({ data: [] });
  const [enemyMemberState, setEnemyMemberState] = useState({ data: [] });


  // 中間段のプレイタイプのボタンの状態
  const [playButtonState, setPlayButtonState] = useState({disabled:true});

  //　中間段のシュートコースボタンの状態
  const [shootButtonState, setShootButtonState] = useState({disabled:true});

  // 下段のゴールボタンの状態
  const [goalButtonState, setGoalButtonState] = useState({disabled:true});

  // 名前ボタンの状態
  const [memberButtonState, setMemberButtonState] = useState();

  // ボタンのクリック状態 というか送信するデータ本体
  const [playState, setPlayState] = useState(pd);

  // snackbarのstate
  const [open, setOpen] = useState(false);
  
  //試合終了ボタンの状態
  const [endGame, setEndGame] = useState(false);

  // 画面遷移のオブジェクト
  const history = useHistory();

  //ボタンの色の状態
  const [colerState, setColerState] =useState({color : 'primary'});

  // 自チーム点数値
  const [ourScoreState, setOurScoreState] = useState("");

  // 相手チーム点数値
  const [enemyScoreState, setEnemyScoreState] = useState("");



  // 自チームと敵チームと区別してデータ取得
  useEffect(() => {
    //ゲームid取得
    api({
      url: "v1/games/" + props.match.params.gameid + "/",
    }).then((response) => {
      // console.log(response);
      const team_1 = response.data.team_1;
      const team_2 = response.data.team_2;

      //自チームのメンバー取得
      api({
        url: `v1/player/?team__id=${team_1}`,
      }).then((response) => {
        console.log(response);
        setMemberState({ data: response.data });
      });

      //敵チームのメンバー取得
      api({
        url: `v1/player/?team__id=${team_2}`,
      }).then((response) => {
        console.log(response);
        setEnemyMemberState({ data: response.data });
      });
    });
  }, []);

  function memberclick(i: string) {
    
    setColerState({color:"sencondary"})
    setPlayButtonState({disabled:false})

    let pd = playState
    pd.player = Number(i);
    setPlayState(pd)
    console.log(playState)
  }

  function playclick(i: number) {

    let pd = playState
    pd.play_type = i
    setPlayState(pd)
    console.log(playState)



    if(i === 11) {
      pd.result=false;
      pd.shoot_type = 1
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 12) {
      pd.result=false;
      pd.shoot_type = 1
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 13) {
      pd.result=false;
      pd.shoot_type = 1
      setPlayState(pd)
      send_play()

      return;
    }
    setShootButtonState({disabled:false})
  }


  function shootclick(i: number) {

    let pd = playState
    pd.shoot_type = i
    setPlayState(pd)
    console.log(playState)

    if(i === 2) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 3) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 4) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 5) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 6) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 7) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 11) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 12) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 16) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 17) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    if(i === 21) {
      pd.result=false;
      setPlayState(pd)
      send_play()

      return;
    }
    setGoalButtonState({disabled:false})

  }

  function goalclick(result: boolean) {
    let pd = playState
    pd.result = result;
    setPlayState(pd)
    console.log(playState)
    //データベースに送信
    send_play()
  }

  function send_play() {
    console.log(playState);
    api({
      data: playState,
      url: "v1/playdata/",
      method: "post"
    })
    .then((response) => {
      console.log(response);
      // alertを右下に出す
      setOpen(true)
      // ボタンを初期状態に戻す
      setPlayButtonState({disabled: true})
      setShootButtonState({disabled: true})
      setGoalButtonState({disabled: true})


    })
    .catch((err) => {
      // エラー処理
      //console.log(JSON.stringify(err.response.data));
      alert(JSON.stringify(err.response.data));
    });
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  
  const handleEndClickOpen = () => {
    setEndGame(true);
  }

  // 確認ダイアログを閉じる
  const handleEndClose = () => {
    setEndGame(false)
  }
  
  // 確認ダイアログ、肯定
  const handleEndSendAndClose = () => {

    // 点数を送信する
    // console.log(ourScoreState)
    // console.log(enemyScoreState)

    api({
      data: {
        points_1: ourScoreState,
        points_2: enemyScoreState
      },
      url: "v1/games/" + props.match.params.gameid + "/",
      method: "patch"
    })
    .then((response) => {
      console.log(response);
      history.push("/game/" + props.match.params.gameid + "/");
    })
    .catch((err) => {
      // エラー処理
      //console.log(JSON.stringify(err.response.data));
      alert(JSON.stringify(err.response.data));
    });
  }

  
  return (
    <div className="App-spacing">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert>登録完了</Alert>
      </Snackbar>
      <h1>試合データ入力</h1>
      <h2>自チームメンバー</h2>
      <div className={classes.button}>
        {/* <Hoge color="primary" /> */}
        {/* {console.log(props)} */}
        {memberState.data.map((row: { id: string; name: string }) => (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => memberclick(row.id)}
          >
            {row.name}
          </Button>
        ))}
      </div>
      <Divider />
      <div className={classes.button}>
        <h2>相手チームメンバー</h2>
        {enemyMemberState.data.map((row: { id: string; name: string }) => (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => memberclick(row.id)}
          >
            {row.name}
          </Button>
        ))}
      </div>
      <br />
      <br />
      <Divider />
      <div className={classes.button}>
        <h2>ポジション</h2>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(1)}
        >
          ポスト
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(2)}
        >
          左サイド
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(3)}
        >
          右サイド
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(4)}
        >
          ロング
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(5)}
        >
          ミドル
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(6)}
        >
          ドライブ
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(7)}
        >
          リバウンド
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(8)}
        >
          フリースロー
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(9)}
        >
          速攻
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(10)}
        >
          7mシュート
        </Button>
        <br />
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(11)}
        >
          7m獲得
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(12)}
        >
          セットミス
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={playButtonState.disabled}
          onClick={() => playclick(13)}
        >
          速攻ミス
        </Button>
      </div>
      <br />
      <Divider />
      <div className={classes.button}>
        <h2>シュートコース</h2>
      </div>
      <div className={classes.button}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(2)}
        >
          枠外
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(3)}
        >
          枠外
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(4)}
        >
          枠外
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(5)}
        >
          枠外
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(6)}
        >
          枠外
        </Button>
        <br />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(7)}
        >
          枠外
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(8)}
        >
          左上
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(9)}
        >
          中上
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(10)}
        >
          右上
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(11)}
        >
          枠外
        </Button>
        <br />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(12)}
        >
          枠外
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(13)}
        >
          左中
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(14)}
        >
          真中
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(15)}
        >
          右中
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(16)}
        >
          枠外
        </Button>
        <br />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(17)}
        >
          枠外
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(18)}
        >
          左下
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(19)}
        >
          中下
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={shootButtonState.disabled}
          onClick={() => shootclick(20)}
        >
          右下
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={shootButtonState.disabled}
          className="goalbutton"
          onClick={() => shootclick(21)}
        >
          枠外
        </Button>
      </div>
      <br />
      <Divider />
      <div className={classes.goalButton}>
        <h2>ゴール</h2>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={goalButtonState.disabled}
          onClick={() => goalclick(true)}
        >
          Goal
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={goalButtonState.disabled}
          onClick={() => goalclick(false)}
        >
          No goal
        </Button>
      </div>
      <Divider />
      <br />
      <br />
      <br />
      <br />
      <div>
        <Button
          className={classes.endButton}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleEndClickOpen}
        >
          試合終了
        </Button>
        <Dialog
          open={endGame}
          onClose={handleEndClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"試合終了しますか?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              試合を終了するとデータの変更ができなくなります。本当にOKですか？
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="ourScore"
              label="自チーム得点"
              type="number"
              value={ourScoreState}
              onChange={(e) => setOurScoreState(e.target.value)}
              required
            />
            <TextField
              autoFocus
              margin="dense"
              id="enemyScore"
              label="敵チーム得点"
              type="number"
              value={enemyScoreState}
              onChange={(e) => setEnemyScoreState(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEndClose} color="primary">
              やっぱりやめる
            </Button>
            <Button onClick={handleEndSendAndClose} color="primary" autoFocus>
              試合終了する
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default PlayData;

