import React, { useState, useEffect } from "react";
import axios from "axios";
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PieChart, Pie, Legend, Cell, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';



//import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({

// });
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableName: {
      'div & > *': {
        fontWeight: 'bolder'
      }
    },
    pieGraph: {

    },
  })
);


const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  method: "get",
  timeout: 5000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});


interface GameState {
  data: {
    id: string;
    datetime: string;
    game_name: string;
    points_1: string;
    points_2: string;
    movie_url: string;
  } | {
    isLoading?: Boolean;
  }
}

function GmaeDetails(props: any) {

  const [gameState, setGameState] = useState({
    id: "",
    datetime: "",
    game_name: "",
    team_1: "",
    team_2: "",
    points_1: "",
    points_2: "",
    movie_url: ""
  })

  useEffect(() => {
    console.log(props);
    api({
      url: "v1/games/" + props.gameid + "/",
    }).then((response) => {
      console.log(response);
      setGameState(response.data);
    }).catch((err) => {
      // エラー処理
      //console.log(JSON.stringify(err.response.data));
      alert(JSON.stringify(err.response.data));
    });
  }, [props])

  return (
    <div>
      <ul>
        <li>
          試合名: {gameState.game_name} {gameState.points_1}対
          {gameState.points_2}
        </li>
        <li>自分チームID: {gameState.team_1}</li>
        <li>相手チームID: {gameState.team_2}</li>
        <li>自分チーム得点: {gameState.points_1}</li>
        <li>相手チーム得点: {gameState.points_2}</li>
        <li>YouTube動画: {gameState.movie_url}</li>
      </ul>
    </div>
  );
}

interface PlayData {
  id: number;
  name: string;
  PV_shoot: number;
  PV_goal: number;
  LW_shoot: number;
  LW_goal: number
  RW_shoot: number;
  RW_goal: number;
  long_shoot: number;
  long_goal: number;
  middle_shoot: number;
  middle_goal: number;
  drive_shoot: number;
  drive_goal: number;
  rebound_shoot: number;
  rebound_goal: number;
  freeThrow_shoot: number;
  freeThrow_goal: number
  fastBreak_shoot: number;
  fastBreak_goal: number;
  sevenMeter_get: number;
  sevenMeter_shoot: number;
  sevenMeter_goal: number;
  setMiss: number;
  fastBreakMiss: number;
}

let sample_play_data: PlayData = {
  id: 1,
  name: "ddddd",
  PV_shoot: 0,
  PV_goal: 0,
  LW_shoot: 0,
  LW_goal: 0,
  RW_shoot: 0,
  RW_goal: 0,
  long_shoot: 0,
  long_goal: 0,
  middle_shoot: 0,
  middle_goal: 0,
  drive_shoot: 0,
  drive_goal: 0,
  rebound_shoot: 0,
  rebound_goal: 0,
  freeThrow_shoot: 0,
  freeThrow_goal: 0,
  fastBreak_shoot: 0,
  fastBreak_goal: 0,
  sevenMeter_get: 0,
  sevenMeter_shoot: 0,
  sevenMeter_goal: 0,
  setMiss: 0,
  fastBreakMiss: 0
}

function addPlayerResult(player: number, play_type: number, result: boolean, pd: Array<PlayData>) {
  pd.forEach(element => {
    if (element.id === player) {
      switch (play_type) {
        case 1:
          if (result === true) {
            element.PV_shoot++;
            element.PV_goal++;
          } else {
            element.PV_shoot++;
          }
          break;
        case 2:
          if (result === true) {
            element.LW_shoot++;
            element.LW_goal++;
          } else {
            element.LW_shoot++;
          }
          break;
        case 3:
          if (result === true) {
            element.RW_shoot++;
            element.RW_goal++;
          } else {
            element.RW_shoot++;
          }
          break;
        case 4:
          if (result === true) {
            element.long_shoot++;
            element.long_goal++;
          } else {
            element.long_shoot++;
          }
          break;
        case 5:
          if (result === true) {
            element.middle_shoot++;
            element.middle_goal++;
          } else {
            element.middle_shoot++;
          }
          break;
        case 6:
          if (result === true) {
            element.drive_shoot++;
            element.drive_goal++;
          } else {
            element.drive_shoot++;
          }
          break;
        case 7:
          if (result === true) {
            element.rebound_shoot++;
            element.rebound_goal++;
          } else {
            element.rebound_shoot++;
          }
          break;
        case 8:
          if (result === true) {
            element.freeThrow_shoot++;
            element.freeThrow_goal++;
          } else {
            element.freeThrow_shoot++;
          }
          break;
        case 9:
          if (result === true) {
            element.fastBreak_shoot++;
            element.fastBreak_goal++;
          } else {
            element.fastBreak_shoot++;
          }
          break;
        case 10:
          element.sevenMeter_get++;
          break;
        case 11:
          if (result === true) {
            element.sevenMeter_shoot++;
            element.sevenMeter_goal++;
          } else {
            element.sevenMeter_shoot++;
          }
          break;
        case 12:
          element.setMiss++;
          break;
        case 13:
          element.fastBreakMiss++;
          break;
      }
    }
  });
}

function ScoreSheet(props: any) {
  const classes = useStyles();
  // const [member, setMemberState] = useState({ data: [] })

  const [playDataState, setPlayDataState] = useState<Array<PlayData>>([])

  useEffect(() => {
    let playdata: Array<PlayData> = [];
    // playdata.push({...sample_play_data})
    // playdata.push({...sample_play_data})
    // playdata.push({...sample_play_data})
    // playdata.push({...sample_play_data})

    // playdata[0].id = 2;
    // playdata[1].id = 3;
    // playdata[2].id = 4;
    // playdata[3].id = 5;
    // console.log(playdata);

    // addPlayerResult(2, 1, true, playdata)
    // addPlayerResult(2, 1, true, playdata)
    // addPlayerResult(2, 1, false, playdata)
    // addPlayerResult(4, 9, false, playdata)
    // addPlayerResult(4, 8, false, playdata)

    // addPlayerResult(4, 12, false, playdata)
    // addPlayerResult(4, 3, false, playdata)
    // addPlayerResult(4, 4, false, playdata)
    // addPlayerResult(4, 5, false, playdata)
    // addPlayerResult(4, 6, false, playdata)

    // setPlayDataState(playdata);


    api({
      url: "v1/player/",
    }).then((response) => {
      // setMemberState({ data: response.data });
      response.data.map((m: any) => {
        const pos = playdata.push({ ...sample_play_data }) - 1;
        playdata[pos].id = m.id;
        playdata[pos].name = m.name
        return 0;
      })

      api({
        url: "v1/playdata/?game__id=" + props.gameid,
      }).then((response) => {
        for (var playDataItem of response.data) {

          addPlayerResult(playDataItem.player, playDataItem.play_type, playDataItem.result, playdata)
          setPlayDataState(playdata.concat())
        }
        // console.log(playdata)
      });
    });
  }, [props])

  return (
    <div>
      <h2>スコアシート</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableName}>
              <TableCell>名前</TableCell>
              <TableCell align="right">ポスト</TableCell>
              <TableCell align="right">左サイド</TableCell>
              <TableCell align="right">右サイド</TableCell>
              <TableCell align="right">ロング</TableCell>
              <TableCell align="right">ミドル</TableCell>
              <TableCell align="right">ドライブ</TableCell>
              <TableCell align="right">リバウンド</TableCell>
              <TableCell align="right">フリースロー</TableCell>
              <TableCell align="right">速攻</TableCell>
              <TableCell align="right">7m獲得</TableCell>
              <TableCell align="right">7mシュート</TableCell>
              <TableCell align="right">セットミス</TableCell>
              <TableCell align="right">速攻ミス</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playDataState.map((row: PlayData) => (
              <TableRow key={row.id} component={Link} to={`/member/${row.id}/`}>
                <TableCell scope="row">{row.name}</TableCell>
                <TableCell align="right">{row.PV_goal}/{row.PV_shoot}</TableCell>
                <TableCell align="right">{row.LW_goal}/{row.LW_shoot}</TableCell>
                <TableCell align="right">{row.RW_goal}/{row.RW_shoot}</TableCell>
                <TableCell align="right">{row.long_goal}/{row.long_shoot}</TableCell>
                <TableCell align="right">{row.middle_goal}/{row.middle_shoot}</TableCell>
                <TableCell align="right">{row.drive_goal}/{row.drive_shoot}</TableCell>
                <TableCell align="right">{row.rebound_goal}/{row.rebound_shoot}</TableCell>
                <TableCell align="right">{row.freeThrow_goal}/{row.freeThrow_shoot}</TableCell>
                <TableCell align="right">{row.fastBreak_goal}/{row.fastBreak_shoot}</TableCell>
                <TableCell align="right">{row.sevenMeter_get}</TableCell>
                <TableCell align="right">{row.sevenMeter_goal}/{row.sevenMeter_shoot}</TableCell>
                <TableCell align="right">{row.setMiss}</TableCell>
                <TableCell align="right">{row.fastBreakMiss}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (pp: any) => {
  const radius = pp.innerRadius + (pp.outerRadius - pp.innerRadius) * 0.70;
  const x = pp.cx + radius * Math.cos(-pp.midAngle * RADIAN);
  const y = pp.cy + radius * Math.sin(-pp.midAngle * RADIAN);
  if (pp.percent !== 0) {
    return (
      <text x={x} y={y} fill="white" textAnchor={x > pp.cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(pp.percent * 100).toFixed(0)}%`}
      </text>
    );
  } else {
    return;
  }
};

function ScoreGraph(props: any) {
  let data01 = [
    {
      "name": "ポスト",
      "value": 0
    },
    {
      "name": "左サイド",
      "value": 0
    },
    {
      "name": "右サイド",
      "value": 0
    },
    {
      "name": "ロング",
      "value": 0
    },
    {
      "name": "ミドル",
      "value": 0
    },
    {
      "name": "カットイン",
      "value": 0
    },
    {
      "name": "リバウンド",
      "value": 0
    },
    {
      "name": "フリースロー",
      "value": 0
    },
    {
      "name": "速攻",
      "value": 0
    },
    {
      "name": "7m",
      "value": 0
    },
  ];

  const [graphDataState, setGraphDataState] = useState(data01);

  const Colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ff0099', '#660000', '#666666', '#cccc00', '#00ff00'];

  class Totals {
    totalGoal: number;
    pvGoalTotal: number;
    lwGoalTotal: number;
    rwGoalTotal: number;
    longGoalTotal: number;
    middleGoalTotal: number;
    driveGoalTotal: number;
    reBoundGoalTotal: number;
    freeThrowGoalTotal: number;
    fastBreakGoalTotal: number;
    sevenMeterGoalTotal: number;

    constructor() {
      this.totalGoal = 0;
      this.pvGoalTotal = 0;
      this.lwGoalTotal = 0;
      this.rwGoalTotal = 0;
      this.longGoalTotal = 0;
      this.middleGoalTotal = 0;
      this.driveGoalTotal = 0;
      this.reBoundGoalTotal = 0;
      this.freeThrowGoalTotal = 0;
      this.fastBreakGoalTotal = 0;
      this.sevenMeterGoalTotal = 0;
    }

    generateArrayForRecharts(): Array<any> {
      const result = [
        {
          name: "ポスト",
          value: this.pvGoalTotal,
        },
        {
          name: "左サイド",
          value: this.lwGoalTotal,
        },
        {
          name: "右サイド",
          value: this.rwGoalTotal,
        },
        {
          name: "ロング",
          value: this.longGoalTotal,
        },
        {
          name: "ミドル",
          value: this.middleGoalTotal,
        },
        {
          name: "カットイン",
          value: this.driveGoalTotal,
        },
        {
          name: "リバウンド",
          value: this.reBoundGoalTotal,
        },
        {
          name: "フリースロー",
          value: this.freeThrowGoalTotal,
        },
        {
          name: "速攻",
          value: this.fastBreakGoalTotal,
        },
        {
          name: "7m",
          value: this.sevenMeterGoalTotal,
        },
      ];
      return result;
    }

    static countPositionGoals(play_data: Array<any>): any {
      let result = {
        totalGoal: 0,
        pvGoalTotal: 0,
        lwGoalTotal: 0,
        rwGoalTotal: 0,
        longGoalTotal: 0,
        middleGoalTotal: 0,
        driveGoalTotal: 0,
        reBoundGoalTotal: 0,
        freeThrowGoalTotal: 0,
        fastBreakGoalTotal: 0,
        sevenMeterGoalTotal: 0,
      };

      play_data.map((item) => {
        if (item.result === true) {
          switch (item.play_type) {
            case 1:
              result.totalGoal++;
              result.pvGoalTotal++;
              break;
            case 2:
              result.totalGoal++;
              result.lwGoalTotal++;
              break;
            case 3:
              result.totalGoal++;
              result.rwGoalTotal++;
              break;
            case 4:
              result.totalGoal++;
              result.longGoalTotal++;
              break;
            case 5:
              result.totalGoal++;
              result.middleGoalTotal++;
              break;
            case 6:
              result.totalGoal++;
              result.driveGoalTotal++;
              break;
            case 7:
              result.totalGoal++;
              result.reBoundGoalTotal++;
              break;
            case 8:
              result.totalGoal++;
              result.freeThrowGoalTotal++;
              break;
            case 9:
              result.totalGoal++;
              result.fastBreakGoalTotal++;
              break;
            case 11:
              result.totalGoal++;
              result.sevenMeterGoalTotal++;
              break;
          }
        }
        return 0;
      });
      return result;
    }
  }

  const fetchUrlGames = `${process.env.REACT_APP_API_ENDPOINT}v1/games/`;
  const fetchUrlPlayerWithTeam = (x: number) =>
    `${process.env.REACT_APP_API_ENDPOINT}v1/player/?team__id=${x}`;
  const fetchUrlPlayDataWithGameAndPlayer = (
    gameid: number,
    playerid: number
  ) =>
    `${process.env.REACT_APP_API_ENDPOINT}v1/playdata/?game__id=${gameid}&player__id=${playerid}`;
  
  const fetchOptions: RequestInit = {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    // チーム別でplaydataを取得し、その合計を計算する。
    props.getTeamId().then((res: any) => {
      let teamid = 1;
      if (props.teamKind === 1) {
        teamid = res.team_1;
      } else {
        teamid = res.team_2;
      }
      
      fetch(fetchUrlPlayerWithTeam(teamid), fetchOptions)
        .then((res) => res.json())
        .then((players) => {
          // console.log(players);

          // 指定したゲームの中でプレイヤーごとのplaydataを取得
          const promisedArray: Array<Promise<Totals>> = players.map(
            (player: any) => {
              return new Promise((resolve) => {
                // console.log(player.id);
                fetch(
                  fetchUrlPlayDataWithGameAndPlayer(props.gameid, player.id),
                  fetchOptions
                )
                  .then((res) => res.json())
                  .then((playdatas) => {
                    // console.log(
                    //   `playdata for gameid:${props.gameid} teamid:${props.teamid} playerid:${player.id}`
                    // );
                    // console.log(playdatas);
                    let r;
                    if (playdatas.length !== 0) {
                      r = Totals.countPositionGoals(playdatas);
                    }
                    resolve(r);
                  });
              });
            }
          );

          // 配列に入ったゴールの合計値を統合
          Promise.all(promisedArray).then((arrayOfTotalGoals) => {
            // console.log(arrayOfTotalGoals);
            const t: Totals = arrayOfTotalGoals.reduce(
              (acc: Totals, cur: any) => {
                if (cur !== undefined) {
                  acc.pvGoalTotal += cur.pvGoalTotal;
                  acc.lwGoalTotal += cur.lwGoalTotal;
                  acc.rwGoalTotal += cur.rwGoalTotal;
                  acc.longGoalTotal += cur.longGoalTotal;
                  acc.middleGoalTotal += cur.middleGoalTotal;
                  acc.driveGoalTotal += cur.driveGoalTotal;
                  acc.reBoundGoalTotal += cur.reBoundGoalTotal;
                  acc.freeThrowGoalTotal += cur.freeThrowGoalTotal;
                  acc.fastBreakGoalTotal += cur.fastBreakGoalTotal;
                  acc.sevenMeterGoalTotal += cur.sevenMeterGoalTotal;
                }
                return acc;
              },
              new Totals()
            );
            console.log(t.generateArrayForRecharts());
            setGraphDataState(t.generateArrayForRecharts());
          });
        });
    });
  }, []);

  return (
    <div>
      <h2>得点エリア割合</h2>
      <PieChart width={360} height={360}>
        <Legend verticalAlign="top" height={70} />
        <Pie data={graphDataState} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={140} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
          {
            data01.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={Colors[index]} />
            ))
          }
        </Pie>
      </PieChart>
    </div>
  )
}

function ScoreBarGraph(props: any) {
  const Colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '6600ff', '#660000', '#666666', '#cccc00', '#00ff00'];
  // const data = [
  //   {
  //     "name": "遥",
  //     "shootSucessRates": 100,
  //     "shootAchievementRates": 100,
  //     "missRates": 100,
  //     "goalAchievementRates": 100
  //   },
  //   {
  //     "name": "茉由",
  //     "shootSucessRates": 100,
  //     "shootAchievementRates": 100,
  //     "missRates": 100,
  //     "goalAchievementRates": 100
  //   },
  //   {
  //     "name": "Page C",
  //     "shootSucessRates": 100,
  //     "shootAchievementRates": 100,
  //     "missRates": 100,
  //     "goalAchievementRates": 100
  //   },
  //   {
  //     "name": "Page D",
  //     "shootSucessRates": 100,
  //     "shootAchievementRates": 100,
  //     "missRates": 100,
  //     "goalAchievementRates": 100
  //   },
  //   {
  //     "name": "Page E",
  //     "shootSucessRates": 100,
  //     "shootAchievementRates": 100,
  //     "missRates": 100,
  //     "goalAchievementRates": 100
  //   },
  //   {
  //     "name": "Page F",
  //     "shootSucessRates": 100,
  //     "shootAchievementRates": 100,
  //     "missRates": 100,
  //     "goalAchievementRates": 100
  //   },
  //   {
  //     "name": "Page G",
  //     "shootSucessRates": 100,
  //     "shootAchievementRates": 100,
  //     "missRates": 100,
  //     "goalAchievementRates": 100
  //   }
  // ]

  const [barGraphState, setBarGraphState] = useState<Array<any>>()

  class BarGraphMember {
    id: number;
    name: string;
    shootSucessRates: number;
    shootAchievementRates: number;
    missRates: number;
    goalAchievementRates: number;
    totalGoals: number;
    totalShoots: number;
    sevenMeterGet: number;
    totalSetMiss: number;
    totalFastBreakMiss: number;
    
    constructor(id: number, name: string) {
      this.id = id;
      this.name = name;
      this.shootSucessRates = 0;
      this.shootAchievementRates = 0;
      this.missRates = 0;
      this.goalAchievementRates = 0;
      this.totalGoals = 0;
      this.totalShoots = 0;
      this.sevenMeterGet = 0;
      this.totalSetMiss = 0;
      this.totalFastBreakMiss = 0;
    }
  }


  useEffect(() => {
    let barGraph: Array<any> = [];
    props.getTeamId().then((res: any) => {
      let teamid: number = 1;

      if (props.teamKind === 1) {
        teamid = res.team_1;
      } else {
        teamid = res.team_2;
      }

      api({
        url: "v1/player/",
      }).then((response) => {
        response.data.map((member: any) => {
          if (member.team === teamid) {
            barGraph.push(new BarGraphMember(member.id, member.name));
          }
        });
        //setBarGraphState(barGraphState);
        console.log(barGraph);

        api({
          url: "v1/playdata/?game__id=" + props.gameid,
        }).then((response) => {
          barGraph.map((member: BarGraphMember) => {
            response.data.map((play_data: any) => {
              if (member.id === play_data.player) {
                if (play_data.result === true) {
                  member.totalGoals++;
                  member.totalShoots++;
                } else {
                  switch (play_data.play_type) {
                    case 10:
                      member.sevenMeterGet++;
                      break;
                    case 12:
                      member.totalSetMiss++;
                      break;
                    case 13:
                      member.totalFastBreakMiss++;
                      break;
                    default:
                      member.totalShoots++;
                      break;
                  }
                }
              }
              // console.log(play_data)
            });
            // console.log(member);
            barGraph.map((member: BarGraphMember) => {
              member.shootSucessRates = +(
                (member.totalGoals / member.totalShoots) *
                100
              ).toFixed();
              member.shootAchievementRates =
                (member.totalShoots /
                  (member.sevenMeterGet +
                    member.totalSetMiss +
                    member.totalFastBreakMiss +
                    member.totalShoots)) *
                100;
              member.missRates =
                ((member.totalSetMiss + member.totalFastBreakMiss) /
                  (member.sevenMeterGet +
                    member.totalSetMiss +
                    member.totalFastBreakMiss +
                    member.totalShoots)) *
                100;
              member.goalAchievementRates =
                (member.totalGoals /
                  (member.sevenMeterGet +
                    member.totalSetMiss +
                    member.totalFastBreakMiss +
                    member.totalShoots)) *
                100;
            });
          });
          // console.log(barGraph);
          setBarGraphState(barGraph);
        });
      });
    });
  }, [])


  return (
    <div>
      <h2>個人データ</h2>
      <BarChart width={1500} height={250} data={barGraphState}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="shootSucessRates" name="シュート率" fill={Colors[0]} />
        <Bar dataKey="shootAchievementRates" name="シュート達成率" fill={Colors[1]} />
        <Bar dataKey="missRates" name="ミス率" fill={Colors[2]} />
        <Bar dataKey="goalAchievementRates" name="ゴール達成率" fill={Colors[3]} />
      </BarChart>
    </div>
  )
}

// function ScatterGraph(props: any) {
//   const data01 = [
//     { x: 1, y: 7, z: 1},
//     { x: 3, y: 7, z: 2 },
//     { x: 5, y: 7, z: 10 },
//     { x: 7, y: 7, z: 100 },
//     { x: 9, y: 7, z: 1 },
//     { x: 1, y: 5, z: 1},
//     { x: 3, y: 5, z: 2 },
//     { x: 5, y: 5, z: 10 },
//     { x: 7, y: 5, z: 100 },
//     { x: 9, y: 5, z: 1 },
//     { x: 1, y: 3, z: 1},
//     { x: 3, y: 3, z: 2 },
//     { x: 5, y: 3, z: 10 },
//     { x: 7, y: 3, z: 100 },
//     { x: 9, y: 3, z: 1},
//     { x: 1, y: 1, z: 1},
//     { x: 3, y: 1, z: 2 },
//     { x: 5, y: 1, z: 10 },
//     { x: 7, y: 1, z: 100 },
//     { x: 9, y: 1, z: 1 },
//   ];
//   const data02 = [
//     { x: 3.1, y: 5, z: 100 },
//     { x: 5, y: 5, z: 0 },
//     { x: 7, y: 5, z: 0 },
//     { x: 3, y: 3, z: 0 },
//     { x: 5, y: 3, z: 0 },
//     { x: 7, y: 3, z: 0 },
//     { x: 3, y: 1, z: 0 },
//     { x: 5, y: 1, z: 0 },
//     { x: 7, y: 1, z: 0 },
//   ];

//   const data03 = [
//     { x: 2, y: 0, z: 0 },
//     { x: 2, y: 6, z: 0 },
//     { x: 8, y: 6, z: 0 },
//     { x: 8, y: 0, z: 0 },
//   ]
//   console.log(data01)
  
//   return (
//     <div>
//       <ScatterChart width={750} height={400}
//         margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
//         {/* <CartesianGrid strokeDasharray="3 3" /> */}
//         <XAxis dataKey="x" type="number" domain={[0, 10]}/>
//         <YAxis dataKey="y" domain={[0, 8]}/>
//         <ZAxis dataKey="z" range={[10,100]}/>
//         <Tooltip cursor={{ strokeDasharray: '3 3' }} />
//         <Legend />
//         <Scatter name="No Goal" data={data01} fill="#8884d8" shape="cross" />
//         <Scatter name="Goal" data={data02} fill="#82ca9d" />
//         <Scatter data={data03} fill="#ff0000" line shape="cross" />
//       </ScatterChart>
//     </div>
//   )
// }


function GameInfo(props: any) {
  // const classes = useStyles();

  const fetchUrlGame = (id: number) => `${process.env.REACT_APP_API_ENDPOINT}v1/games/${id}/`;
  const fetchOptions: RequestInit = {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  // チームIDを含むJSONのPromiseを返す
  function getTeamId() {
    return fetch(fetchUrlGame(props.match.params.gameid), fetchOptions)
      .then((res) => res.json());
  }

  // teamKind=1は味方チーム、2が敵チーム
  return (
    <div className="App-spacing">
      <h1>試合情報</h1>
      <GmaeDetails gameid={props.match.params.gameid} />
      <br />
      <ScoreGraph
        gameid={props.match.params.gameid}
        getTeamId={getTeamId}
        teamKind={1}
      />
      <br />
      <ScoreGraph
        gameid={props.match.params.gameid}
        getTeamId={getTeamId}
        teamKind={2}
      />
      <ScoreBarGraph
        gameid={props.match.params.gameid}
        getTeamId={getTeamId}
        teamKind={1}
      />
      <br />
      <ScoreBarGraph
        gameid={props.match.params.gameid}
        getTeamId={getTeamId}
        teamKind={2}
      />
      <ScoreSheet gameid={props.match.params.gameid} />
    </div>
  );
}

export default GameInfo;
