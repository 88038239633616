import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import './Movie.css';




// const useStyles = makeStyles({
//     table: {
//         minWidth: 650,
//     },
// });

function Movie() {
     // const classes = useStyles();

     return (
    <header className="App-header">
        <h1>
            Coming soon...
        </h1>
    </header>
         );
}

export default Movie;
