import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import axios from "axios";
import './App.css';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            width: 200,
        },
        spacing: {
          paddingLeft:theme.spacing(3),
        },
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
    }),
);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  method: "post",
  timeout: 5000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});


function InputGame() {
  type TeamType = {
    id: number;
    name: string;
  };
    const classes = useStyles();
    const [datatimeState, setDateTimeState] = useState("");
    const [gamenameState, setGameNameState] = useState("");
    const [team1_id, setTeam1IdState] = React.useState(1);
    const [team2_id, setTeam2IdState] = React.useState(1);

    const fetchUrlTeam = `${process.env.REACT_APP_API_ENDPOINT}v1/team/`;
    // const fetchUrlGames = `${process.env.REACT_APP_API_ENDPOINT}v1/games/`;
    const fetchOptions: RequestInit = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const fetchOptionsPost = (data: any): RequestInit => {
    //   // fetchの場合はX-CSRFTokenを付与しないとPOSTできない
    //   const csrftokenkv: string | undefined = document.cookie
    //     .split("; ")
    //     .find((row) => row.startsWith("csrftoken"));

    //   const csrftoken = csrftokenkv ? csrftokenkv.split("=")[1] : "";
    //   return {
    //     method: "POST",
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-CSRFToken": csrftoken,
    //     },
    //     body: JSON.stringify(data),
    //   };
    // }


    // チーム一覧の取得用
    const [teamState, setTeamState] = useState<TeamType[]>(
      [
        {
          id:-1,
          name: ""
        },
      ]
    );

    const history = useHistory();

    useEffect(() => {
      // チーム一覧を取得
      fetch(fetchUrlTeam, fetchOptions)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setTeamState( res );
      });
    }, []);


    function putGame(state: any) {
        // POST用データの生成
        const data = {
            game_name: state.gamenameState,
            datetime: state.datatimeState,
            team_1: state.team1_id,
            team_2: state.team2_id,
            points_1: 0,
            points_2: 0,
            movie_url: "null",
        };
        console.log(state);

        // エラー処理がうまくいかないので保留
        // fetch(fetchUrlGames, fetchOptionsPost(data))
        //   .then((res) => {
        //     if (!res.ok) {
        //       //return res.json();
        //       res.json().then((err) => {throw Error(err.message)});
        //     } else {
        //       return res.json();
        //     }
        //   })
        //   .then((res) => {
        //     // 成功で次の画面に移動する
        //     console.log(res);
        //     console.log(res.id);
        //     history.push("/inputgame/" + res.id + "/");
        //   })
        //   .catch((err) => {
        //     // エラー処理
        //     console.log(err);
        //     alert(JSON.stringify(err));
        //   });

        api({
            data: data,
            url: "v1/games/",
        })
        .then((response) => {
          console.log(response);
          // ここで次の画面に移動する
          console.log(response.data.id);
          history.push("/inputgame/"+response.data.id+"/");
        })
        .catch((err) => {
          // エラー処理
          //console.log(JSON.stringify(err.response.data));
          alert(JSON.stringify(err.response.data));
        });
    }

    return (
      <div className="App-spacing">
        <h1>試合開始</h1>
        <form noValidate autoComplete="off">
          <TextField
            id="standard-basic"
            className={classes.textField}
            label="試合名"
            value={gamenameState}
            onChange={(e) => setGameNameState(e.target.value)}
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="team1_select_label">自チーム</InputLabel>
            <Select
              labelId="team1_select_label"
              id="team1_select"
              value={team1_id}
              onChange={(e) => setTeam1IdState(e.target.value as number)}
            >
              {teamState.map((team: TeamType) => {
                return (
                  <MenuItem value={team.id} key={team.id}>
                    {team.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="team2_select_label">敵チーム</InputLabel>
            <Select
              labelId="team2_select_label"
              id="team2_select"
              value={team2_id}
              onChange={(e) => setTeam2IdState(e.target.value as number)}
            >
              {teamState.map((team: TeamType) => (
                <MenuItem value={team.id} key={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <TextField
            id="datetime-local"
            label="試合開始時刻"
            type="datetime-local"
            className={classes.textField}
            value={datatimeState}
            onChange={(e) => setDateTimeState(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              putGame({ datatimeState, gamenameState, team1_id, team2_id });
            }}
          >
            試合開始
          </Button>
        </form>
      </div>
    );
}


function InputGame1(props: any) {

  return (
    <div className="App-spacing">
      <InputGame />
    </div>
  );
}
export default InputGame1;


// export default withRouter(InputGame):
